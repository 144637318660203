import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { getMethod } from '../helper/api';
import { navigate } from "gatsby";
import { notify } from 'react-notify-toast';

const Review = ({ location, user }) => {

    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState(null);
    const [cancelDetails, setCancelDetails] = useState({});
    const [feedbackTime, setFeedbackTime] = useState("");

    const [srating, setSRating] = useState(0);
    const [sfeedback, setSFeedback] = useState(null);
    const [sfeedbackTime, setSFeedbackTime] = useState("");

    useEffect(() => {
        getMyReview()
        async function getMyReview() {

            let sub2 = await getMethod('/reviews', '', { 'booking': location.state.booking?.id});
            //console.log(user);
            if(sub2.data.length>0){
                for(let i =0; i< sub2.data.length; i++){
                    
                    if(sub2.data[i].postedBy.user_role === 'student'){
                        setSRating(sub2.data[i].rating);
                        setSFeedback(sub2.data[i].feedback);
                        setSFeedbackTime(sub2.data[i].created_at);
                    }else{
                        setRating(sub2.data[i].rating);
                        setFeedback(sub2.data[i].feedback);
                        setFeedbackTime(sub2.data[i].created_at);
                    }
                }
            }
        }

    }, [rating])

    useEffect(()=>{
        async function getCancelDetails() {

            let sub2 = await getMethod('/cancel-bookings', '', { 'booking': location.state.booking?.id});
            //console.log(user);
            if(sub2.data.length>0){
                setCancelDetails(sub2.data[0])
            }
        }

        if(location.state.booking?.isCancelled){
            getCancelDetails();
        }

    },[])

    if(!location.state){
        location.state = {}
    }

    return (
        <Layout title="Report Card">
            <Seo title="Report Card" />
            <div className="container">
                <div className="report-section">
                    <div className="row">
                        <div className="col-6 ms-auto">
                            {/* <div className="select-wrapper">
                                <select className="form-control form-control-sm">
                                    <option value="--Select--" key="0">Omar</option>
                                    <option value="Omar">Omar</option>
                                    <option value="Omar">Omar</option>
                                    <option value="Omar">Omar</option>
                                </select>
                            </div>     */}
                        </div>
                    </div>
                    {/* <div className="row gx-3">
                        <div className="col">
                            <div className="card text-center">
                                <div>Total</div>
                                <h3 className="mb-0">14</h3>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card text-center">
                                <div>Completed</div>
                                <h3 className="mb-0 text-success">10</h3>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="mb-3">
                    <Booking booking={location.state.booking} showLessonHistory={false} />

                    {location.state.booking?.isCancelled && <>
                        <div className="row gx-3">
                        <div className="col">
                            <label className="sm">Cancel Reason</label>
                        </div>
                        </div>
                        <div className="card card-info">
                        <div><b>Reason:</b> {cancelDetails?.reasons}</div>
                        
                        <div style={{paddingTop: '15px'}}><b>Comments:</b> {cancelDetails?.remarks}</div>
                        </div>
                    </>}
                    
                    <div className="row gx-3">
                        <div className="col">
                            <label className="sm">Student Remarks</label>
                        </div>
	    		{/* <div className="col-auto">
                            <a className="btn btn-sm btn-link">View Recording</a>
                        </div> */ }
                    </div>
                    <div className="card card-info">
                        <span>{sfeedback}</span><span style={{float:"right"}} className="teacher-card">
                        {[...Array(srating)].map((e,i)=>
                                <i className="icon-Star_Fill" key={i}></i>
                            )}
                            </span>
                            <span>{sfeedbackTime?new Date(sfeedbackTime).toLocaleString('default',{'dateStyle':'medium',timeStyle:'short'}):''}</span>
                    </div>


                    <div className="row gx-3">
                        <div className="col">
                            <label className="sm">Tutor Remarks</label>
                        </div>
	    		{/* <div className="col-auto">
                            <a className="btn btn-sm btn-link">View Recording</a>
                        </div> */}
                    </div>
                    <div className="card card-info ">
                        <span>{feedback}</span><span style={{float:"right"}} className="teacher-card">
                            
                            {[...Array(rating)].map((e,i)=>
                                <i className="icon-Star_Fill" key={i}></i>
                            )}
                        
                        </span>
                        <span>{feedbackTime?new Date(feedbackTime).toLocaleString('default',{'dateStyle':'medium',timeStyle:'short'}):''}</span>
                    </div>
                   
                </div>    
            </div>    
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings, user: state.loginReducer }), null)(Review);
